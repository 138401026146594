import Siema from 'siema';

document.addEventListener("turbolinks:load", function(event) {

  const event_offers_carousel = document.getElementById('event-offers-carousel');
  if(event_offers_carousel){
    const mySiema = new Siema({
      selector: '#event-offers-carousel',
      loop: true,
      duration: 500,
    });

    setInterval(() => mySiema.next(), 5000)
  }

})
