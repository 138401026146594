export function savedSearchSwitch(element) {
  element.addEventListener('click', function(){ toggleSwitch(event) }, false)
}

function toggleSwitch(event){
  event.preventDefault()
  const container = event.target.closest('.saved-search-switch') 
  container.classList.toggle("off")

  const http = new XMLHttpRequest() 

  const check_url = container.dataset.checkUrl +".json"
  const uncheck_url = container.dataset.uncheckUrl + ".json"

  if(container.classList.contains("off")){
    http.open("GET", uncheck_url, true)
  } else {
    http.open("GET", check_url, true)
  }

  http.send()
}
  
