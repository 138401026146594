
document.addEventListener("turbolinks:load", function(event) {
  const menu_buttons = document.querySelectorAll('.header2_menu_button')
  const cross_buttons = document.querySelectorAll('.header2_cross_button')
  const back_buttons = document.querySelectorAll('.header2_back_button')
  const overlay = document.querySelector('.header2_menu_overlay')
  const tabs = document.querySelectorAll('.header2_nav_menu .header2_tab')

  const level1_to_level2_links = document.querySelectorAll('.header2_menu_level_one_link_to_level2')

  level1_to_level2_links.forEach(function(link){
    link.addEventListener("click", openLevel2, false)
  })

  menu_buttons.forEach(function(button){
    button.addEventListener("click", openMenu, false)
  })

  cross_buttons.forEach(function(button){
    button.addEventListener("click", closeMenu, false)
  })

  back_buttons.forEach(function(button){
    button.addEventListener("click", backToLevel1, false)
  })

  tabs.forEach(function(tab){
    tab.addEventListener('click', switchTab, false)
  })

  if(overlay) {
    overlay.addEventListener('click', closeMenu, false)
  }
})

function switchTab(event){
  event.preventDefault()

  if(!event.target.classList.contains('current')){
    const menus = document.querySelectorAll('.header2_menu_container')
    const tabs = document.querySelectorAll('.header2_nav_menu .header2_tab')
    const sticky_rows = document.querySelectorAll('.header2_sticky_row')

    menus.forEach(function(menu){
      menu.classList.toggle('current')
    })

    sticky_rows.forEach(function(sticky_row){
      sticky_row.classList.toggle('current')
    })

    tabs.forEach(function(tab){
      tab.classList.toggle('current')
    })

    // hide cta
    const cta_rows = document.querySelectorAll('.header2_menu_cta')
    cta_rows.forEach(function(cta){
      cta.classList.add("header2_menu_cta_hide")
    })

    // hide flying cta
    const flying_ctas = document.querySelectorAll('.flying-cta')
    flying_ctas.forEach(function(cta){
      cta.classList.add("flying-cta-hide")
    })
  }

  return false;
}

function openMenu(event){
  event.preventDefault()

  const nav = document.querySelector('.header2_nav')
  const nav_menu = document.querySelector('.header2_nav_menu')
  const nav_overlay = document.querySelector('.header2_menu_overlay') 

  nav.classList.add("header2_nav_show")
  nav_menu.classList.add("header2_nav_menu_show")
  nav_overlay.classList.add("header2_menu_overlay_show")
}

function closeMenu(event){
  event.preventDefault()

  const nav = document.querySelector('.header2_nav')
  const nav_menu = document.querySelector('.header2_nav_menu')
  const nav_overlay = document.querySelector('.header2_menu_overlay') 

  nav.classList.remove("header2_nav_show")
  nav_menu.classList.remove("header2_nav_menu_show")
  nav_overlay.classList.remove("header2_menu_overlay_show")

  backToLevel1()
}

function openLevel2(event){
  event.preventDefault()

  const home_buttons = document.querySelectorAll('.header2_home_button')
  home_buttons.forEach(function(home_button){
    home_button.classList.add("header2_home_button_hide")
  })

  const back_buttons = document.querySelectorAll('.header2_back_button')
  back_buttons.forEach(function(back_button){
    back_button.classList.add("header2_back_button_show")
  })

  const level2_menu = event.target.parentElement.querySelector('.header2_menu_level_two')
  level2_menu.classList.add("header2_menu_level_two_show")
}

function backToLevel1Handler(event){
  event.preventDefault()

  backToLevel1()
}

function backToLevel1(){

  const home_buttons = document.querySelectorAll('.header2_home_button')
  home_buttons.forEach(function(home_button){
    home_button.classList.remove("header2_home_button_hide")
  })

  const back_buttons = document.querySelectorAll('.header2_back_button')
  back_buttons.forEach(function(back_button){
    back_button.classList.remove("header2_back_button_show")
  })

  closeOpenLevel2Menus()
}

function closeOpenLevel2Menus(){
  const open_level2_menus = document.querySelectorAll('.header2_menu_level_two_show')
  open_level2_menus.forEach(function(level2_menu){
    level2_menu.classList.remove('header2_menu_level_two_show') 
  })
}
