document.addEventListener("turbolinks:load", function(event) {
  document.querySelectorAll('.save_search_for_logged_candidate').forEach(function(element){
    savedSearchPopup(element)    
  })

  const saved_search_popup_close_button = document.querySelector('.saved_search_popup .saved_search_popup-close')
  if(saved_search_popup_close_button){
    saved_search_popup_close_button.addEventListener("click", function(event) { closePopup(event) }, false)
  }
})

function savedSearchPopup(element) {
  element.addEventListener('click', function(){ togglePopup(event) }, false)
}

function togglePopup(event){
  event.preventDefault()
  disableAllSaveSearchButtons()
  displayPopup(event.target)
}

function disableAllSaveSearchButtons(){
  const saved_search_buttons = document.querySelectorAll(".save_search_for_logged_candidate")
  saved_search_buttons.forEach(function(element){
    element.classList.add('disable')
  })
}

function displayPopup(element){
  const container = element.closest('.save_search_wrapper')
  const popup = container.querySelector('.saved_search_popup')
  popup.classList.add('show')
  popup.classList.remove("hide")
}

function closePopup(event){
  event.preventDefault()
  const container = event.target.closest('.save_search_wrapper')
  const popup = container.querySelector('.saved_search_popup')
  popup.classList.add("hide")
  popup.classList.remove("show")
}
