import { loadAddthis } from '../components/addthis.js'
import { sticky } from '../components/sticky.js'
import { toggleCoachContactDetails } from '../components/coach.js'
import { savedSearchSwitch } from '../components/saved_search_switch.js'

import 'menu' 
import 'saved_search_popup'
import 'components/event_offers_carousel'
import 'components/flying_cta'

document.addEventListener("turbolinks:load", function(event) {
  document.querySelectorAll('.addthis_container').forEach(function (addthisContainer) {
    loadAddthis(addthisContainer)
  })

  document.querySelectorAll('.cta_box_sticky_true').forEach(function(sticky_container){
    const container_position = sticky_container.offsetTop
    window.addEventListener('scroll', function(event){
      sticky(sticky_container, container_position)
    })
  })

  const toggle_coach_contact_details = document.querySelector('.toggle-coach-contact-details')
  if(toggle_coach_contact_details){
    toggle_coach_contact_details.addEventListener("click", function(e) {
      e.preventDefault()
      toggleCoachContactDetails()
    })
  }

  document.querySelectorAll('.saved-search-switch').forEach(function(element){
    savedSearchSwitch(element)    
  })
})
