export function loadAddthis(container) {
  console.log('Load AddThis');

  // Remove all global properties set by addthis, otherwise it won't reinitialize
  if (window.addthis){
    window.addthis = null;
    window._adr = null;
    window._atc = null;
    window._atd = null;
    window._ate = null;
    window._atr = null;
    window._atw = null;
  }

  for (var i in window) {
    if (/^addthis/.test(i) || /^_at/.test(i)) {
      delete window[i];
    }
  }

  window.addthis_share = null;

  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5cb1a4be146a8298';
  script.async = 'async'
  var body = document.getElementsByTagName("body")[0];
  body.appendChild(script);
}
