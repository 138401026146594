document.addEventListener("turbolinks:load", function(event) {
  document.addEventListener("scroll", function(event){
    sticky = document.querySelector('.flying-cta')

    if(sticky){
      if (window.pageYOffset  >= 100) {
        sticky.classList.add("flying-cta-sticky")
      } else {
        sticky.classList.remove("flying-cta-sticky");
      }
    }
  })
})
